import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import { useFaceDetection } from "../hooks/useFaceDetection.ts";
import FaceDetection from "@mediapipe/face_detection";
import { Camera } from "@mediapipe/camera_utils";
import axios from "axios";

const width = 720;
const height = 360;

const FaceDetector = ({ isOpened, setIsCameraModalOpen,setPatientIdfromFaceDetection }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [capturedImage, setCapturedImage] = useState(null);
  const [faceAlreadyDetectedOnce, setFaceAlreadydetectedOnce] = useState(null);

  const { webcamRef, detected, facesDetected } = useFaceDetection({
    faceDetectionOptions: {
      model: "short",
    },
    faceDetection: new FaceDetection.FaceDetection({
      locateFile: (file) =>
        `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
    }),
    camera: ({ mediaSrc, onFrame }) =>
      new Camera(mediaSrc, {
        onFrame,
        width,
        height,
      }),
  });

  const fetchData = async (screenshotDataURI) => {
    if (detected && facesDetected === 1 && screenshotDataURI) {
      setPatientIdfromFaceDetection(null);
      setFaceAlreadydetectedOnce(screenshotDataURI);
      try {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const image = new Image();
        image.onload = async () => {
          canvas.width = image.width;
          canvas.height = image.height;
          ctx.drawImage(image, 0, 0, image.width, image.height);
          canvas.toBlob(
            async (blob) => {
              const formData = new FormData();
              formData.append("file", blob, `face_capture.jpg`);

              const response = await axios.post(
                "https://prabudentalpatientrecognitionprod.azurewebsites.net/api/HttpTrigger1?code=dvDu6XufYIYq-UmLsm_xRmxpacMzoHt4GjbEmSO4n-TWAzFuzXhhgQ==",
                formData,
                {
                  headers: {
                    "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
                  },
                }
              );

              if (response.status !== 200) {
                setError(response?.data.message);
              }
              else if(response.data.message == 'Success'){
                setPatientIdfromFaceDetection(response.data.employees[0].PatientId)
                console.log("API call successful", response.data.employees[0].PatientId);
              }
            },
            "image/jpeg",
            0.95
          );
        };
        image.src = screenshotDataURI;
        setLoading(false);
        setIsCameraModalOpen(false)
      } catch (error) {
        setError("Error calling API: " + error.message);
        setLoading(false);
        setIsCameraModalOpen(false)
      }
    }
  };

  const stopCam = () => {
    let stream = webcamRef.current.stream;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    setIsCameraModalOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const screenshot = webcamRef.current.getScreenshot();
      setCapturedImage(screenshot);
    }, 2000);
    return () => clearInterval(interval);
  }, [webcamRef]);


  useEffect(() => {
    console.log("summaa2",detected)
    const handleDownloadImage = () => {
      setLoading(true);
      if (capturedImage && faceAlreadyDetectedOnce == null) {
        fetchData(capturedImage);
      }
    };
    handleDownloadImage();
  }, [detected, facesDetected, capturedImage]);

  useEffect(() => {
    setIsInitialRender(false);
    if (!isOpened && !isInitialRender) {
      stopCam();
    }
  }, [isOpened]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          Loading...
        </div>
      )}
      <Webcam
        ref={webcamRef}
        forceScreenshotSourceSize
        style={{ width, height }}
      />
      {detected && facesDetected === 1 && !loading && (
        <p style={{ color: "green", textAlign: "center" }}>
          Face detected, calling API...
        </p>
      )}
      {!detected ? (
        <p style={{ color: "red", textAlign: "center" }}>Face not detected </p>
      ) : facesDetected > 1 ? (
        <p style={{ color: "red", textAlign: "center" }}>
          more than one face detected
        </p>
      ) : (
        ""
      )}
      {error && (
        <p style={{ color: "red", textAlign: "center" }}>Error: {error}</p>
      )}
    </div>
  );
};

export default FaceDetector;
