import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Dashboardlayout } from "./dashboard/Dashboardlayout";
import { Details } from "./dashboard/Metrics";
import { Signin } from "./Signin/Signin";
import { MsalProvider } from "@azure/msal-react";
import PatientInfo from "./dashboard/PatientInfo";
import PatientList from "./dashboard/PatientList";
import FaceDetector from "./dashboard/FaceDetection";
import LogDetails from "./dashboard/LogDetails";
import React, { useEffect, useState } from "react";

function App({ msalInstance }) {
  const [toggleValue, setToggleValue] = useState(localStorage?.getItem('togglevalue'));

  useEffect(() => {
    console.log(toggleValue, 'DDDDD');
    !!toggleValue
      ? localStorage.setItem('togglevalue', toggleValue)
      : localStorage.setItem('togglevalue', true)

    setToggleValue(localStorage.getItem('togglevalue'))
  }, [])

  return (
    <>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <Routes>
            <Route path="/" element={<Signin />} />
            <Route element={<Dashboardlayout toggleValue={toggleValue} setToggleValue={setToggleValue} />}>
              <Route path="/dashboard" element={<Details toggleValue={toggleValue} />} />
              <Route path="/dashboard/logdetail" element={<LogDetails />} />
              <Route path="/patientInfo/:id?" element={<PatientInfo />} />
              <Route path="/patientList" element={<PatientList toggleValue={toggleValue} />} />
              <Route path="/facedetection" element={<FaceDetector />} />
            </Route>
          </Routes>
        </MsalProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
const Loader = () => {
  return (
    <div className="flex h-screen items-center justify-center bg-white">
      <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
    </div>
  );
};
