import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import axios from "axios";
import { Form, Input } from "antd";

export const Signin = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [isSigningIn, setIsSigningIn] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);


  const handleSignInWithMicrosoft = async () => {
    setIsSigningIn(true);
    instance.loginRedirect({
      scopes: ["user.read"],
    });
  };

  const handleLogIn = () => {
    setShowLoginForm(true);
  };

  const handleBack = () => {
    setShowLoginForm(false);
  };

  const handleLoginSubmit = async (values) => {
    try {
      const res = await axios.post(
        "https://dev-prabudentalnodeapi.jetzerp.com/login",
        {
          username: values.username,
          password: values.password,
        }
      );
      if (res.data.data === 1) {
        sessionStorage.setItem("isLoggedIn", "1");
        sessionStorage.setItem("username", values.username);
        const adminName = "Admin";
        sessionStorage.setItem("adminName", adminName);
        setShowLoginForm(false);
        navigate("/dashboard");
      } else {
        throw new Error("Invalid Username or Password");
      }
    } catch (error) {
      form.setFields([
        {
          name: "password",
          errors: [error.message],
        },
      ]);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      const account = instance.getActiveAccount();
      if (account) {
        sessionStorage.setItem("firstName", account.name.split(" ")[0]);
        navigate("/dashboard");
      }
      setIsSigningIn(false);
    }
  }, [isAuthenticated]);

  return (
    <div>
      <div className="h-screen flex sm:flex-row flex-col">
        <div className="flex justify-center items-center flex-1">
          <img src="./prabhudental.png" alt="Prabhu Dental Logo" />
        </div>
        <div className="bg-blue-100 flex flex-col gap-6 justify-center items-center flex-1 p-10 sm:p-12.5 xl:p-17.5">
          <img className="sm:max-w-full max-w-48" src="./jetz-logo.png" />
          {!showLoginForm ? (
            <>
              <button
                className="bg-[#3f51b5] w-full text-white justify-center px-5 py-5 rounded-md flex gap-5 items-center"
                style={{ backgroundColor: "#3f51b5", color: "white" }}
                onClick={handleSignInWithMicrosoft}
              >
                <img
                  style={{ width: "1.5em" }}
                  src="./microsoft.png"
                  alt="Microsoft Logo"
                />
                Sign in with Microsoft
              </button>
              <div className="flex items-center gap-2">
                <p className="text-blue">or</p>
                <a
                  className="decoration-transparent hover:decoration-blue-500 cursor-pointer hover:text-blue-700 hover:underline"
                  onClick={handleLogIn}
                >
                  Admin Login
                </a>
              </div>
            </>
          ) : (
            <>
              <Form
                form={form}
                onFinish={handleLoginSubmit}
                className="w-full max-w-sm  p-6 rounded-md"
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  maxWidth: 600,
                }}
                initialValues={{
                  remember: true,
                }}
                autoComplete="on"
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <div className="flex items-center justify-end">
                  <button
                    className="bg-[#3f51b5] text-white py-2 px-6 ml-2 w-[25%] rounded focus:outline-none focus:shadow-outline cursor-pointer flex items-center justify-center gap-2"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button
                    type="primary"
                    htmlType="submit"
                    className="bg-[#3f51b5] text-white py-2 px-6 ml-2 w-[25%] rounded focus:outline-none focus:shadow-outline cursor-pointer flex items-center justify-center gap-2"
                  >
                    Login
                  </button>
                </div>
              </Form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
