import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {DownloadOutlined } from "@ant-design/icons";

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#403e6f',
    },
  },
});

function LogDetails() {
  const [backupData, setBackupData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchedValue, setSearchedValue] = useState('');
  const [columnVisibility, setColumnVisibility] = useState({
    PatientId: true,
  });

  const { data,key } = useLocation()["state"];

   //const column_visiblity = (data.find(item => item?.actionKey==='scanned'));
  useEffect(() => {
    (key===3||key===1)&&setColumnVisibility({PatientId: false})  },[key])
 
  // console.log(column_visiblity,data,'data')

  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "SNO",
      //   header: "SNO",
      //   size: 10,
      //   muiTableHeadCellProps: {
      //     align: "left",
      //   },
      //   muiTableBodyCellProps: {
      //     align: "left",
      //   },
      //   enableColumnFilter: false
      // },
      {
        accessorKey: "PatientId",
        header: "Patient Id",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      // {
      //   accessorKey: "FileName",
      //   header: "File",
      //   size: 150,
      //   muiTableHeadCellProps: {
      //     align: "center",
      //   },
      //   muiTableBodyCellProps: {
      //     align: "center",
      //   },
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   Cell: ({row}) => {
      //     return (
      //       <a
      //         href={row.original.FileName}
      //       //  target="_blank"
      //         className='bg-[#403e6f] text-white  px-5 py-1 rounded-md'
      //         download
      //       >
      //         Download
      //       </a>
      //     )
      //   }
      // },
      {
        accessorKey: "Type",
        header: "Type",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        filterVariant: "select",
        filterSelectOptions: [
          { label: "SUCCESS", value: "SUCCESS" },
          { label: "FAILED", value: "FAILED" },
        ],
      },
      {
        accessorKey: "Message",
        header: "Message",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "Created_At",
        header: "Date",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "FileName",
        header: "Download",
        size: 150,
        muiTableHeadCellProps: {
          align: "center",
          fontWeight: 'bold',
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({row}) => {
          return (
            <a
              href={row.original.FileName}
            //  target="_blank"
             
              download
            >
            <DownloadOutlined style={{color:'#403e6f',fontSize:'22px'}}/>
            </a>
          )
        }
      },
    ],
    [tableData]
  );

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableHiding: true,
    enableTopToolbar: true,
    enableColumnResizing: true,
    enableColumnFilter: false,
    searchIconVisible: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    enablePagination:tableData.length>0?true:false, 
    //onColumnVisibilityChange: setColumnVisibility,
    initialState: { density: "comfortable", pagination: { pageSize: 10 } },
    state: {
      isLoading,
      columnVisibility
    },
    layoutMode: "semantic",
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 50],
    },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(even) > td': {
          backgroundColor: '#f0f4f7',
        
        },
        // '& tr:nth-of-type(1) > td': {
        //   border:'none'
        // },
 
 
      },
    },
    muiTableBodyCellProps: {
      sx: {
        // borderRight: 'none',
         border:'none',
      //add a border between columns
      },
    },
    muiTableHeadCellProps: {
      sx: (theme) => ({
        fontSize: '14px',
        fontWeight: 'bold',
        border:'none',
        backgroundColor: '#f0f4f7',
 
        //add a border between columns
      }),
    },

    muiBottomToolbarProps:{
      sx:{
        backgroundColor: '#ffff',
      
      }
    },
    muiTableFooterCellProps:{
      sx:{
        border:'none',
      }
    }
   
  });

  useEffect(() => {

    const alteredData = data.map((item, index) => (
        {
          ...item,
          SNO: index + 1,
          Created_At: item.Created_At,
          Type: item.Type.toUpperCase()
        }
    ));
    setTableData(alteredData);
    setBackupData(alteredData);
    setIsLoading(false);

  }, [data]);

  useEffect(() => {

    if (searchedValue.length > 0) {
      const searchedData = backupData.filter(item => (
        item?.PatientId?.toLocaleLowerCase()?.includes(searchedValue?.toLocaleLowerCase())
          || item?.Message?.toLocaleLowerCase()?.includes(searchedValue?.toLocaleLowerCase())
          || item?.Type?.toLocaleLowerCase()?.includes(searchedValue?.toLocaleLowerCase())
          || item?.Created_At?.toLocaleLowerCase()?.includes(searchedValue?.toLocaleLowerCase())
      ));
      setTableData(searchedData);
    } 
    
    else {
      const alteredData = data.map((item, index) => (
        {
          ...item,
          SNO: index + 1,
          Created_At: item.Created_At,
          Type: item.Type.toUpperCase()
        }
      ));
      setTableData(alteredData);
    }


  }, [searchedValue])

  return (
    <>
      <div className="flex justify-between align-center">
        <h4
          style={{ marginBottom: "-10px" }}
          className="text-[#403E6F] text-lg font-semibold"
        >
          Log Details{" "}
        </h4>
      </div>
      <div
        style={{
          display: "flex",
          position: "relative",
          top: "15px",
          gap: "10px",
        }}
      >
        <div className="searchbar">
          <Input
            placeholder="Search"
            style={{ width: "220px" }}
            prefix={
              <SearchOutlined style={{ fontSize: "20px", color: "#D3D2D6" }} />
            }
            onChange={(e) => setSearchedValue(e.target.value.trim())}
          />
        </div>
      </div>
      <ThemeProvider theme={customTheme}>
        <div className="patient-table">
          <MaterialReactTable table={table} />
        </div>
      </ThemeProvider>
      <div className="flex justify-start mb-5 mt-5">
        <span></span>
        <button
          className="btn close-btn "
          onClick={() => {navigate('/dashboard')}}
        >
          Back
        </button>
      </div>
    </>
  )
}

export default LogDetails
