import React, { useEffect, useState, useRef, useCallback } from "react";
import { Input, Button, Select, DatePicker, ConfigProvider } from "antd";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import CollectionsRoundedIcon from "@mui/icons-material/CollectionsRounded";
import AddAPhotoRoundedIcon from "@mui/icons-material/AddAPhotoRounded";
import { Modal, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Diagnosis from "./sidebar/Diagnosis";
import Webcam from "react-webcam";
import { useForm, Controller } from "react-hook-form"
import { ErrorMessage } from '@hookform/error-message';
import { toast } from "react-toastify";

const videoConstraints = {
  width: 720,
  height: 360,
  facingMode: "user"
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const initialPayloadData = {
  General: [
    {
      idno: "",
      name: "",
      age: "",
      gender: "",
      date: dayjs(new Date()).format("YYYY-MM-DD"),
      patient_img: "",
      marital_status: "",
      blood_group: "",
      date_of_birth: "",
      occupation: "",
      address: "",
      refered_by: "self",
      mobile: "",
      email: "",
      asthma: "unselected",
      diabetes: "unselected",
      arthritis: "unselected",
      jaundice: "unselected",
      liver_disease: "unselected",
      kidney_disease: "unselected",
      bleeding_disorders: "unselected",
      hepatitis: "unselected",
      blood_pressure: "unselected",
      epilepsy: "unselected",
      heart_problems: "unselected",
      rheumatic_fever: "unselected",
      ulcer: "unselected",
      cancer: "unselected",
      corticosteroid_treatment: "unselected",
      thyroid_problems: "unselected",
      other: "unselected",
      penicillin: "unselected",
      sulfa: "unselected",
      aspirin: "unselected",
      lodine: "unselected",
      local_anaesthetic: "unselected",
      ibuprofen: "unselected"
    }
  ],
  DataRows: [],
  key: 1
}

const initialMH = {
  asthma: false,
  diabetes: false,
  arthritis: false,
  jaundice: false,
  liver_disease: false,
  kidney_disease: false,
  bleeding_disorders: false,
  hepatitis: false,
  blood_pressure: false,
  epilepsy: false,
  heart_problems: false,
  rheumatic_fever: false,
  ulcer: false,
  cancer: false,
  corticosteroid_treatment: false,
  thyroid_problems: false,
  other: false,
};

const initialDA = {
  penicillin: false,
  sulfa: false,
  aspirin: false,
  lodine: false,
  local_anaesthetic: false,
  ibuprofen: false
};

export default function PatientInfo() {
  const [tabValue, setTabValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isdrugModalOpen, setIsdrugModalOpen] = useState(false);
  const [restoremodal, setrestoremodal] = useState({ modal_open: false, patient_details: {}, data_exsist: null })
  const [medicalHistory, setMedicalHistory] = useState(initialMH);
  const [drugAllergy, setDrugAllergy] = useState(initialDA);
  const [previewImg, setPreviewImg] = useState(null);
  const [isCameraModalOpen, setIsCameraModalOpen] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [payloadData, setPayloadData] = useState(initialPayloadData);
  const [imageValidated, setImageValidated] = useState(false)
  const [buttonLoad, setButtonLoad] = useState(false);
  const [diagnosisFields, setDiagnosisFields] = useState([]);
  const [params, setParams] = useState(null);

  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
    control
  } = useForm();

  const { id } = useParams();
  const toggleValue = localStorage.getItem('togglevalue')
  const { lastpatientid, backupData } = (useLocation().state || {}) || null;
  const navigate = useNavigate();
  console.log(backupData, 'backupData')
  const galleryRef = useRef(null);
  const webcamRef = useRef(null);

  const handleChanges = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAddButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleButtonClick = () => {
    setIsdrugModalOpen(true);
  };

  const handleDate = (name, value) => {
    const date = dayjs(value).format("YYYY-MM-DD");
    setValue(name, date);
    date && clearErrors(name)
  };

  const handleNameInput = (event, id) => {
    const inputValue = event.target.value;

    const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
    if (inputValue !== sanitizedValue) {
      event.target.value = sanitizedValue;
      setValue(id, sanitizedValue);
    } else {
      setValue(id, inputValue);
    }
    event.preventDefault();
  };

  const handleAgeInput = (event) => {
    let inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/\D/g, '').slice(0, 3);
    if (inputValue !== sanitizedValue) {
      event.target.value = sanitizedValue;
      setValue('age', sanitizedValue);
    } else {
      setValue('age', sanitizedValue);
    }
    event.preventDefault();
  }

  const handleNumberinput = (event) => {
    let inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/\D/g, '').slice(0, 10);

    if (inputValue !== sanitizedValue) {
      event.target.value = sanitizedValue;
      setValue('mobile', sanitizedValue);
    } else {
      setValue('mobile', sanitizedValue);
    }
    event.preventDefault();
  }

  const handleDelete = (chipToDelete, deleteFrom) => () => {
    setValue(chipToDelete, false);
    setPayloadData(prevState => {
      const obj = {};
      Object.entries(prevState.General[0]).forEach(([key, value]) => {
        if (key === chipToDelete) {
          obj[key] = false;
        } else {
          obj[key] = value;
        }
      });
      return {
        ...prevState,
        General: [{ ...obj }]
      }
    });
    if (deleteFrom === 'MH') {
      setMedicalHistory(prevState => {
        let obj = {};
        Object.entries(prevState).forEach(([key, value]) => {
          if (key === chipToDelete) {
            obj[key] = false;
          } else {
            obj[key] = value;
          }
        });

        return obj;
      });
    } else {
      setDrugAllergy(prevState => {
        let obj = {};
        Object.entries(prevState).forEach(([key, value]) => {
          if (key === chipToDelete) {
            obj[key] = false;
          } else {
            obj[key] = value;
          }
        });

        return obj;
      });
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (!!file) {
      const previewURL = URL.createObjectURL(file);
      setCapturedImage(file);
      setPreviewImg(previewURL);
      setValue('patient_img', previewURL);
      clearErrors('patient_img');
      setImageValidated(false);
    } else {
      setPreviewImg(null);
      setValue('patient_img', '');
      setImageValidated(false);
    }
  };

  const handleTakePicture = useCallback(() => {
    galleryRef.current.value = '';
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      const blob = dataURItoBlob(imageSrc);
      const url = window.URL.createObjectURL(blob);
      setCapturedImage(blob)
      setPreviewImg(url)
      setIsCameraModalOpen(false);
      setValue('patient_img', url);
      clearErrors('patient_img');
      setImageValidated(false);
    }
  }, [webcamRef]);

  const handleSubmitOne = async (data) => {
    try {
      setButtonLoad(true);
      if (capturedImage) {

        const formData = new FormData();
        formData.append("image", capturedImage);
        if (!imageValidated) {
          const validatePatientImg = await axios.post(
            "https://prabudentalpatientauthprod.azurewebsites.net/api/HttpTrigger1?code=o99qZNJPEK8HWmsDVXjZ0BXz3SB-D4ekWRISYe8BFPEcAzFufu7MqA==",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          const validateName = (value) => {
            const prefixRegex = /^(MRS\s?|MR\s?|MS\s?|MISS\s?|MYS\s?)/i;
            return value
              .toUpperCase()
              .replace(prefixRegex, '')
              .trim()
          };

          let validKey = validateName(data.name);

          validKey = `${validKey.replaceAll(' ', '_').trim()}_${id || lastpatientid}.jpeg`;

          if (validatePatientImg.status === 200) {
            setParams({
              // Bucket: "prabhudental",
              Bucket: "prabhudental-dev",
              Key: `Patient_Images/${validKey}`,
              Body: capturedImage,
              ContentType: "image/jpeg",
              ACL: "public-read",
            });
            setImageValidated(true);
            setTabValue(1);
            setPayloadData(prevState => {
              const obj = {};
              Object.entries(prevState.General[0]).forEach(([key, value]) => {
                if (key === 'idno') {
                  obj[key] = id || lastpatientid;
                } else {
                  if (data.hasOwnProperty(key)) {
                    obj[key] = data[key]
                  } else {
                    obj[key] = value
                  }
                }
              });
              return { ...prevState, General: [{ ...obj }] }
            });
            clearErrors();
          }
        } 
        else {
          console.log("validated");
          setTabValue(1);
          setPayloadData(prevState => {
            const obj = {};
            Object.entries(prevState.General[0]).forEach(([key, value]) => {
              if (key === 'idno') {
                obj[key] = id || lastpatientid;
              } else {
                if (data.hasOwnProperty(key)) {
                  obj[key] = data[key] || ''
                } else {
                  obj[key] = value
                }
              }
            });
            return { ...prevState, General: [{ ...obj }] }
          });
        }
      } 
      
      else if (!capturedImage && id) {
        setTabValue(1);
        setPayloadData(prevState => {
          const obj = {};
          Object.entries(prevState.General[0]).forEach(([key, value]) => {
            if (key === 'idno') {
              obj[key] = id || lastpatientid;
            } else {
              if (data.hasOwnProperty(key)) {
                obj[key] = data[key]
              } else {
                obj[key] = value
              }
            }
          });
          return { ...prevState, General: [{ ...obj }] }
        });
        clearErrors();
      }
      setButtonLoad(false);
    } 

    catch (error) {
      setError('patient_img', {
        type: 'manual',
        message: error.response.data.message,
      });
    if(error.response.data.message==='User exists'){
      const data_exsist = Array.isArray(backupData) && backupData.find((patientDetail) => {
        return patientDetail.idno === error.response.data.PatientId
      })
      if (!data_exsist) {
        setrestoremodal((prev) => ({ ...prev, modal_open: true, patient_details: error.response.data, data_exsist: false }))
      }

      else {
        setrestoremodal((prev) => ({ ...prev, modal_open: true, patient_details: error.response.data, data_exsist: true }))
      }

      setButtonLoad(false);
    }

    }
  }


  const handleSubmitTwo = data => {
    let obj = {};
    Object.entries(data).forEach(([key, value]) => {
      if (medicalHistory.hasOwnProperty(key)) {
        obj[key] = !!value ? value : false;
      }
    });
    setMedicalHistory(obj);
    setPayloadData(prevState => {
      const newObj = {};
      Object.entries(prevState.General[0]).forEach(([key, value]) => {
        if (medicalHistory.hasOwnProperty(key)) {
          newObj[key] = data[key];
        } else {
          newObj[key] = value;
        }
      });
      return { ...prevState, General: [{ ...newObj }] }
    });
    setIsModalOpen(false);
  }

  const handleSubmitThree = data => {
    let obj = {};
    Object.entries(data).forEach(([key, value]) => {
      if (drugAllergy.hasOwnProperty(key)) {
        obj[key] = !!value ? value : false;
      }
    });
    setDrugAllergy(obj);
    setPayloadData(prevState => {
      const newObj = {};
      Object.entries(prevState.General[0]).forEach(([key, value]) => {
        if (drugAllergy.hasOwnProperty(key)) {
          newObj[key] = data[key];
        } else {
          newObj[key] = value;
        }
      });
      return { ...prevState, General: [{ ...newObj }] }
    });
    setIsdrugModalOpen(false);
  }

  const handleSubmitFour = () => {
    setPayloadData(prevState => {
      const obj = {};
      Object.entries(prevState.General[0]).forEach(([key, value]) => {
        if (value === true || value === false) {
          obj[key] = value ? 'selected' : 'unselected';
        } else {
          obj[key] = value;
        }
      });
      return {
        ...prevState,
        General: [{ ...obj }]
      }
    });
    setTabValue(2);
  }

  const restore_patient = async () => {
  try{
    if(restoremodal?.data_exsist===false){
    const res = await axios.post(`https://prabudentalnodeapi.jetzerp.com/restore-patient-detail/${restoremodal?.patient_details?.PatientId}`)
  toast.success(res.data.data)
  setTimeout(() => navigate('/patientList'), 2000);
}

  else{
    setTimeout(() => navigate('/patientList'),500);
  }
}

  catch(error){
    
  }

  }

  useEffect(() => {
    try {
      if (id) {
        async function getpatientdetails() {
          const res = await axios.get(
            `https://dev-prabudentalnodeapi.jetzerp.com/get-patient-detail/${id}`
          );

          const patientMedicalHistory = res.data.data['patientMedicalHistory'];
          const patientDetails = res.data.data['patientDetail'];

          setPreviewImg(res.data.data['patientDetail']?.patient_img);

          setPayloadData(prevState => {
            const obj = {};
            Object.entries(prevState.General[0]).forEach(([key, value]) => {
              if (patientMedicalHistory.hasOwnProperty(key)) {
                obj[key] =
                  patientMedicalHistory[key] === 1
                    ? 'selected'
                    : 'unselected';
                setValue(key,
                  patientMedicalHistory[key] === 1
                    ? true
                    : false
                );
              } else {
                obj[key] = patientDetails[key];
                setValue(key, patientDetails[key]);
              }
            });
            return {
              ...prevState,
              General: [{ ...obj }],
              DataRows: res.data.data["patientPrescription"]
            }
          });

          let obj = {};
          Object.entries(patientMedicalHistory).forEach(([key, value]) => {
            if (medicalHistory.hasOwnProperty(key)) {
              obj[key] = value ? value : false;
            }
          });
          setMedicalHistory(obj);

          let obj2 = {};
          Object.entries(patientMedicalHistory).forEach(([key, value]) => {
            if (drugAllergy.hasOwnProperty(key)) {
              obj2[key] = value ? value : false;
            }
          });
          setDrugAllergy(obj2);
        }
        getpatientdetails();
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <ConfigProvider
    theme={{
      components: {
        Button: {
          defaultHoverBorderColor: "#403E6F",
        },
        Input: {
          hoverBorderColor: "#7876B2",
          activeBorderColor: "#7876B2",
          activeShadow:'#7876B2'
        },
        Select:{
          colorPrimary: "#7876B2",
          colorPrimaryHover: "#7876B2",
          colorSuccessBorder: "#403E6F",
          activeShadow:"#7876B2",
          optionActiveBg: "#7876B2",
          activeBorderColor: "#7876B2",
          optionSelectedBg: "#7876B2",//#8B89BD
          optionSelectedColor: "#fff",
        },
        DatePicker:{
          colorPrimary: "#7876B2",
          colorTextPlaceholder: "#7876B2",
          hoverBorderColor: "#7876B2",
          activeBorderColor: "#7876B2",
          activeShadow:"#7876B2",
        //  colorTextDescription: "#7876B2",
          // cellHoverBg: "#7876B2",
          // cellHoverWithRangeBg: "#7876B2",
          // cellRangeBorderColor: "#7876B2",
        }
        }
    }}>
    <div>
      <div className="text-left">
        <h4 className="text-[#403E6F] text-lg font-semibold dark:text-white">
          Patient Details
        </h4>
      </div>
      <Box className={toggleValue ? `tab-section` : `tab-section1`} sx={{ width: "100%" }}>
        <Box>
          <Tabs
            value={tabValue}
            onChange={id ? handleChanges : null}
            aria-label="basic tabs example"
          >
            <Tab label="Profile" {...a11yProps(0)} />
            <Tab label="History" {...a11yProps(1)} />
            <Tab label="Diagnosis" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel className="CustomTabPanel" value={tabValue} index={0}>
          {/* <Button onClick={() => setrestoremodal((prev)=>({...prev,exsist:false}))}>restore</Button> */}
          <Modal
            open={restoremodal.modal_open}
            onClose={() => setrestoremodal((prev) => ({ ...prev, modal_open: false }))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-container"
          >

            <div className="modal">

              <div className="modal-header">
                <Typography className="text-md font-medium text-[#403E6F]">
                  Restore Patient
                </Typography>

                <CancelIcon
                  onClick={() => setrestoremodal((prev) => ({ ...prev, modal_open: false }))}
                  style={{
                    color: "#afafaf",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                />
              </div>

              <div className="modal-body">
                {/* <FaceDetector
              isOpened={isOpened}
              setIsCameraModalOpen={setIsCameraModalOpen}
            /> */}

                Patient_Id: {`${restoremodal?.patient_details?.PatientId}`} <br />
                Patient_Name: {`${restoremodal?.patient_details?.name?.split('/')[1]}`} <br />
                {restoremodal?.data_exsist === false ?

                  <p className="text-md mt-5">
                    This Patient You trying to create is already exist and in inactive state .Do you want to restore this patient?
                  </p>

                  :
                  <p className="text-md mt-5">
                    This pateint you trying to create is already exist.
                  </p>

                  }

              </div>
              < div className="modal-footer">
              {restoremodal?.data_exsist === false ?
               <> <button
                  className="btn yes-btn"
                  onClick={() => restore_patient()}
                >
                  Yes
                </button>
                <button
                  className="btn close-btn"
                  onClick={() => setrestoremodal((prev) => ({ ...prev, modal_open: false }))}
                >
                  Back
                </button></>:<button
                  className="btn yes-btn"
                  onClick={() => restore_patient()}
                >
                  Ok
                </button>}
              </div>
            </div>
          </Modal>
          <div
            style={{
              border: "1px solid #c3c3c7",
              borderRadius: "10px",
              marginTop: "20px",
            }}
          >
            <div>

              <form onSubmit={handleSubmit(handleSubmitOne)}>
                <div style={{ padding: "1.5rem 3rem" }}>
                  <div className="grid grid-cols-3 w-full gap-3 gap-x-5 my-3">
                    <div>
                      <label className="text-sm font-medium  dark:text-[#d9e3ea]">
                        <span className="text-red-500">* </span>Photo
                      </label>
                      {!!previewImg && (
                        <div className="w-full h-[150px] rounded overflow-hidden mx-auto">
                          <img
                            src={previewImg}
                            alt="Patient Image"
                            className="w-full h-full object-contain"
                          />
                        </div>
                      )}
                      <div className="flex gap-2 items-end mt-2">
                        <Controller
                          control={control}
                          name="patient_img"
                          rules={{
                            required: 'Patient image is required'
                          }}
                          render={({ field: { onChange, value, ref } }) => (
                            <input
                              ref={(e) => {
                                ref(e);
                                galleryRef.current = e;
                              }}
                              type="file"
                              accept=".jpg, .jpeg, .png"
                              style={{ display: 'none' }}
                              name='patient_img'
                              onChange={(e) => {
                                onChange(e.target.files[0]);
                                handleFileInputChange(e);
                              }}
                            />
                          )}
                        />
                        <button
                          type="button"
                          className="w-[50%] border h-8 rounded"
                          onClick={() => galleryRef.current.click()}
                        >
                          <CollectionsRoundedIcon />
                        </button>
                        <button
                          type="button"
                          className="w-[50%] border h-8 rounded"
                          onClick={() => setIsCameraModalOpen(true)}
                        >
                          <AddAPhotoRoundedIcon />
                        </button>
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="patient_img"
                        render={({ message }) => (
                          <p className="text-red-500 text-sm mt-1">{message}</p>
                        )}
                      />
                    </div>
                    <div>
                      <label className="text-sm font-medium  dark:text-white">
                        {" "}
                        <span className="text-red-500">* </span>Name
                      </label>
                      <Controller
                        control={control}
                        name="name"
                        rules={{
                          required: 'Name is required',
                          minLength: {
                            value: 2,
                            message: 'Name must be at least 3 characters long'
                          },
                          maxLength: {
                            value: 30,
                            message: 'Name must not exceed 30 characters'
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            name="name"
                            className="mt-2"
                            status={errors.name ? 'error' : null}
                            onInput={(e) => handleNameInput(e, 'name')}
                            defaultValue={getValues("name")}
                            placeholder=""
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="name"
                        render={({ message }) => (
                          <p className="text-red-500 text-sm mt-1">{message}</p>
                        )}
                      />
                    </div>
                    <div>
                      <label className="text-sm font-medium dark:text-white">
                        {" "}
                        <span className="text-red-500">* </span>Age
                      </label>
                      <Controller
                        control={control}
                        name="age"
                        rules={{
                          required: 'Age is required',
                          min: {
                            value: 1,
                            message: 'Age must be atleast 1 year old'
                          },
                          max: {
                            value: 150,
                            message: 'Age must not exceed 150 years old'
                          }
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="tel"
                            name="age"
                            className="mt-2"
                            onInput={(e) => handleAgeInput(e)}
                            status={errors.age ? 'error' : null}
                            placeholder=""
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="age"
                        render={({ message }) => (
                          <p className="text-red-500 text-sm mt-1">{message}</p>
                        )}
                      />
                    </div>
                    <div>
                      <label className="text-sm font-medium dark:text-white">
                        {" "}
                        <span className="text-red-500">* </span>Gender
                      </label>
                      <div className="flex-col gap-1 mt-2">
                        <Controller
                          control={control}
                          name="gender"
                          rules={{
                            required: 'Gender is required',
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="w-full"
                              name="gender"
                              defaultValue="-Select-"
                              status={errors.gender ? 'error' : null}
                              options={[
                                {
                                  options: [
                                    { label: <span>Male</span>, value: "male" },
                                    { label: <span>Female</span>, value: "female" },
                                  ],
                                },
                              ]}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="gender"
                          render={({ message }) => (
                            <p className="text-red-500 text-sm mt-1">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-sm font-medium dark:text-white">
                        Marital Status
                      </label>
                      <div className="flex gap-1 mt-2">
                        <Controller
                          control={control}
                          name="marital_status"
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="w-full"
                              name="marital_status"
                              defaultValue="-Select-"
                              status={errors.marital_status ? 'error' : null}
                              options={[
                                {
                                  options: [
                                    {
                                      label: <span>MARRIED</span>,
                                      value: "married",
                                    },
                                    { label: <span>SINGLE</span>, value: "single" },
                                  ],
                                },
                              ]}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="marital_status"
                          render={({ message }) => (
                            <p className="text-red-500 text-sm mt-1">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-sm font-medium dark:text-white">
                        {" "}
                        <span className="text-red-500">* </span>Blood Group
                      </label>
                      <div className="flex-col gap-1 mt-2">
                        <Controller
                          control={control}
                          name="blood_group"
                          rules={{
                            required: 'Blood group is required',
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="w-full"
                              name="blood_group"
                              defaultValue="-Select-"
                              status={errors.blood_group ? 'error' : null}
                              options={[
                                {
                                  options: [
                                    {
                                      label: <span>A +ve</span>,
                                      value: "a+ve",
                                    },
                                    {
                                      label: <span>A -ve</span>,
                                      value: "a-ve",
                                    },
                                    {
                                      label: <span>B +ve</span>,
                                      value: "b+ve",
                                    },
                                    {
                                      label: <span>B -ve</span>,
                                      value: "b-ve",
                                    },
                                    {
                                      label: <span>O +ve</span>,
                                      value: "o+ve",
                                    },
                                    {
                                      label: <span>O -ve</span>,
                                      value: "o-ve",
                                    },
                                  ],
                                },
                              ]}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="blood_group"
                          render={({ message }) => (
                            <p className="text-red-500 text-sm mt-1">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-sm font-medium dark:text-white">
                        <span className="text-red-500">* </span>Date of Birth
                      </label>
                      <Controller
                        control={control}
                        name="date_of_birth"
                        rules={{
                          required: 'D.O.B is required',
                        }}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            className="mt-2 w-full"
                            status={errors.date_of_birth ? 'error' : null}
                            name="date_of_birth"
                            value={
                              getValues('date_of_birth')
                                ? dayjs(getValues('date_of_birth'))
                                : null
                            }
                            onChange={(e) => handleDate("date_of_birth", e)}
                            format={"DD-MM-YYYY"}
                            allowClear={false}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="date_of_birth"
                        render={({ message }) => (
                          <p className="text-red-500 text-sm mt-1">{message}</p>
                        )}
                      />
                    </div>
                    <div>
                      <label className="text-sm font-medium dark:text-white">Occupation</label>
                      <div className="flex gap-1 mt-2">
                        <Controller
                          control={control}
                          name="occupation"
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="w-full"
                              status={errors.occupation ? 'error' : null}
                              name="occupation"
                              defaultValue="-Select-"
                              options={[
                                {
                                  options: [
                                    { label: <span>CEO</span>, value: "CEO" },
                                    { label: <span>DENTIST</span>, value: "DENTIST" },
                                    {
                                      label: <span>DESIGNER</span>,
                                      value: "DESIGNER",
                                    },
                                    { label: <span>DGM</span>, value: "DGM" },
                                    { label: <span>BANKER</span>, value: "BANKER" },
                                    {
                                      label: <span>BUSINESS</span>,
                                      value: "BUSINESS",
                                    },
                                  ],
                                },
                              ]}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="occupation"
                          render={({ message }) => (
                            <p className="text-red-500 text-sm mt-1">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <label className="text-sm font-medium dark:text-white">
                        {" "}
                        <span className="text-red-500">* </span>Address
                      </label>
                      <Controller
                        control={control}
                        name="address"
                        rules={{
                          required: 'Address is required',
                          minLength: {
                            value: 2,
                            message: 'Address must be at least 3 characters long'
                          },
                          maxLength: {
                            value: 100,
                            message: 'Name must not exceed 100 characters'
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            name="address"
                            className="mt-2"
                            status={errors.address ? 'error' : null}
                            placeholder=""
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="address"
                        render={({ message }) => (
                          <p className="text-red-500 text-sm mt-1">{message}</p>
                        )}
                      />
                    </div>
                    <div>
                      <label className="text-sm font-medium dark:text-white">Refered by</label>
                      <Controller
                        control={control}
                        name="refered_by"
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="w-full mt-2"
                            name="refered_by"
                            defaultValue="-Select-"
                            status={errors.refered_by ? 'error' : null}
                            options={[
                              {
                                options: [
                                  { label: <span>Self</span>, value: "self" },
                                ],
                              },
                            ]}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="refered_by"
                        render={({ message }) => (
                          <p className="text-red-500 text-sm mt-1">{message}</p>
                        )}
                      />
                    </div>
                    <div>
                      <label className="text-sm font-medium dark:text-white">
                        {" "}
                        <span className="text-red-500">* </span>Mobile
                      </label>
                      <Controller
                        control={control}
                        name="mobile"
                        rules={{
                          required: 'Mobile No is required',
                          minLength: {
                            value: 10,
                            message: 'Mobile number must be exactly 10 digits long'
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="mt-2"
                            name="mobile"
                            status={errors.mobile ? 'error' : null}
                            onInput={(e) => handleNumberinput(e)}
                            maxLength={10}
                            placeholder=""
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="mobile"
                        render={({ message }) => (
                          <p className="text-red-500 text-sm mt-1">{message}</p>
                        )}
                      />
                    </div>
                    <div>
                      <label className="text-sm font-medium dark:text-white">Email Id</label>
                      <Controller
                        control={control}
                        name="email"
                        rules={{
                          pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                            message: 'Invalid email address'
                          }
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="mt-2"
                            name="email"
                            status={errors.email ? 'error' : null}
                            placeholder=""
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="email"
                        render={({ message }) => (
                          <p className="text-red-500 text-sm mt-1">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center mb-5 gap-3">
                    <span></span>
                    <button
                      className="btn close-btn"
                      onClick={() => navigate("/patientList")}
                      type="button"
                    >
                      Back
                    </button>
                    <Button
                      className="btn save-btn"
                      loading={buttonLoad}
                      onClick={handleSubmit(handleSubmitOne)}
                      // onClick={() => next()}
                      type="submit"
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <Modal
              open={isCameraModalOpen}
              onClose={() => setIsCameraModalOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="modal-container"
            >
              <div className="modal">
                <div className="modal-header">
                  <Typography className="text-md font-medium text-[#403E6F]">
                    Take Picture
                  </Typography>
                  <CancelIcon
                    onClick={() => setIsCameraModalOpen(false)}
                    style={{
                      color: "#afafaf",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div className="modal-body">
                  <Webcam
                    audio={false}
                    height={360}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={720}
                    videoConstraints={videoConstraints}
                  />
                </div>
                <div className="modal-footer">
                  <button className="btn save-btn" onClick={handleTakePicture}>
                    Take Picture
                  </button>
                  <button
                    className="btn close-btn"
                    onClick={() => setIsCameraModalOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </CustomTabPanel>
        <CustomTabPanel className="CustomTabPanel" value={tabValue} index={1}>
          <div
            style={{
              border: "1px solid #c3c3c7",
              borderRadius: "10px",
              marginTop: "20px",
            }}
          >
            <div>
              <div style={{ padding: "1.5rem 3rem" }}>
                <div>
                  <h4
                    style={{ fontFamily: "system-ui", fontSize: "13px" }}
                    className="text-[#403E6F] font-semibold dark:text-[#d9e3ea]"
                  >
                    MEDICAL HISTORY
                  </h4>
                  <div className="pills">
                    <Paper
                      style={{ boxShadow: "none", display: "flex" }}
                      component="ul"
                      className="flex flex-wrap"
                    >
                      {Object.entries(medicalHistory).map(([key, value]) => {
                        return (
                          value && (
                            <div key={key}>
                              <ListItem key={key}>
                                <Chip
                                  label={key}
                                  onDelete={handleDelete(key, 'MH')}
                                />
                              </ListItem>
                            </div>
                          )
                        );
                      })}
                    </Paper>
                    <button
                      className="btn add-btn"
                      onClick={handleAddButtonClick}
                    >
                      Add
                    </button>
                    <Modal
                      open={isModalOpen}
                      onClose={() => setIsModalOpen(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      className="modal-container"
                    >
                      <div className="modal">
                        <div className="modal-header">
                          <Typography className="text-md font-medium text-[#403E6F]">
                            Edit
                          </Typography>
                          <CancelIcon
                            onClick={() => setIsModalOpen(false)}
                            style={{
                              color: "#afafaf",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                        <form onSubmit={handleSubmit(handleSubmitTwo)}>
                          <div className="modal-body">
                            <div className="grid grid-cols-3">
                              <span>
                                <Controller
                                  control={control}
                                  name="asthma"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        name="asthma"
                                        defaultChecked={getValues("asthma")}
                                        {...label}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Asthma
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="diabetes"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        name="diabetes"
                                        defaultChecked={getValues("diabetes")}
                                        {...label}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Diabets
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="liver_disease"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="liver_disease"
                                        defaultChecked={getValues("liver_disease")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Liver Disease
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="thyroid_problems"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="thyroid_problems"
                                        defaultChecked={getValues("thyroid_problems")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Thyroid Problems
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="arthritis"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="arthritis"
                                        defaultChecked={getValues("arthritis")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Arthritis
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="jaundice"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="jaundice"
                                        defaultChecked={getValues("jaundice")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Jaundice
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="kidney_disease"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="kidney_disease"
                                        defaultChecked={getValues("kidney_disease")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Kidney Disease
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="bleeding_disorders"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="bleeding_disorders"
                                        defaultChecked={getValues("bleeding_disorders")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Bleeding Disorder
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="hepatitis"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="hepatitis"
                                        defaultChecked={getValues("hepatitis")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Hepatitis
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="ulcer"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="ulcer"
                                        defaultChecked={getValues("ulcer")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Ulcer
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="blood_pressure"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="blood_pressure"
                                        defaultChecked={getValues("blood_pressure")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Blood Pressure
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="epilepsy"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="epilepsy"
                                        defaultChecked={getValues("epilepsy")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Epilepsy
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="cancer"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="cancer"
                                        defaultChecked={getValues("cancer")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Cancer
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="heart_problems"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="heart_problems"
                                        defaultChecked={getValues("heart_problems")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Heart Problems
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="corticosteroid_treatment"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="corticosteroid_treatment"
                                        defaultChecked={getValues("corticosteroid_treatment")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Corticosteroid Treatment
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="rheumatic_fever"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="rheumatic_fever"
                                        defaultChecked={getValues("rheumatic_fever")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Rheumatic Fever
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="other"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="other"
                                        defaultChecked={getValues("other")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Other
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="submit"
                              className="btn save-btn"
                              onClick={handleSubmit(handleSubmitTwo)}
                            >
                              Save
                            </button>
                            <button
                              className="btn close-btn"
                              type="button"
                              onClick={() => {
                                setIsModalOpen(false);
                              }}
                            >
                              Close
                            </button>
                          </div>
                        </form>
                      </div>
                    </Modal>
                  </div>
                </div>
                <div className="mt-5">
                  <h4
                    style={{ fontFamily: "system-ui", fontSize: "13px" }}
                    className="text-[#403E6F] font-semibold  dark:text-[#d9e3ea]"
                  >
                    DRUG ALLERGY
                  </h4>
                  <div className="pills">
                    <Paper
                      style={{ boxShadow: "none", display: "flex" }}
                      component="ul"
                      className="flex flex-wrap"
                    >
                      {Object.entries(drugAllergy).map(([key, value]) => {
                        return (
                          value && (
                            <div key={key}>
                              <ListItem key={key}>
                                <Chip
                                  label={key}
                                  onDelete={handleDelete(key, 'DA')}
                                />
                              </ListItem>
                            </div>
                          )
                        );
                      })}
                    </Paper>
                    <button className="btn add-btn" onClick={handleButtonClick}>
                      Add
                    </button>
                    <Modal
                      open={isdrugModalOpen}
                      onClose={() => setIsdrugModalOpen(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      className="modal-container"
                    >
                      <div className="modal">
                        <div className="modal-header">
                          <Typography className="text-md font-medium text-[#403E6F]">
                            Edit
                          </Typography>
                          <CancelIcon
                            onClick={() => setIsdrugModalOpen(false)}
                            style={{
                              color: "#afafaf",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                        <form onSubmit={handleSubmit(handleSubmitThree)}>
                          <div className="modal-body">
                            <div className="grid grid-cols-3">
                              <span>
                                <Controller
                                  control={control}
                                  name="penicillin"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        {...label}
                                        name="penicillin"
                                        defaultChecked={getValues("penicillin")}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Penicilin
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="sulfa"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        name="sulfa"
                                        defaultChecked={getValues("sulfa")}
                                        {...label}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Sulfa
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="aspirin"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        name="aspirin"
                                        defaultChecked={getValues("aspirin")}
                                        {...label}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Aspirin
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="lodine"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        name="lodine"
                                        defaultChecked={getValues("lodine")}
                                        {...label}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Iodine
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="ibuprofen"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        name="ibuprofen"
                                        defaultChecked={getValues("ibuprofen")}
                                        {...label}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Ibuprofen
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                              <span>
                                <Controller
                                  control={control}
                                  name="local_anaesthetic"
                                  render={({ field }) => (
                                    <>
                                      <Checkbox
                                        {...field}
                                        name="local_anaesthetic"
                                        defaultChecked={getValues("local_anaesthetic")}
                                        {...label}
                                        size="small"
                                      />
                                      <label className="text-sm font-medium">
                                        Local Anaesthetic
                                      </label>
                                    </>
                                  )}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="submit"
                              className="btn save-btn"
                              onClick={handleSubmit(handleSubmitThree)}
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              className="btn close-btn"
                              onClick={() => {
                                setIsdrugModalOpen(false);
                              }}
                            >
                              Close
                            </button>
                          </div>
                        </form>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mb-5 gap-3">
                <span></span>
                <button
                  className="btn close-btn"
                  onClick={() => {
                    setTabValue(0);
                  }}
                >
                  Back
                </button>
                <Button
                  className="btn save-btn"
                  onClick={handleSubmitFour}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <Diagnosis
            value={tabValue}
            payloadData={payloadData}
            setPayloadData={setPayloadData}
            setvalue={setTabValue}
            initialPayloadData={initialPayloadData}
            diagnosisFields={diagnosisFields}
            setDiagnosisFields={setDiagnosisFields}
            paramsfors3={params}
            id={id}
          />
        </CustomTabPanel>
      </Box>
    </div>
    </ConfigProvider>
  );
}
