import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { RiArrowLeftSLine } from "react-icons/ri";
import { BiSolidDetail } from "react-icons/bi";
export const Sidebar = ({ isDrawerOpen, toggleDrawer }) => {
  const location = useLocation();
  return (
    <aside
      className={
        "left-0 top-0 z-[9999] flex h-screen w-50 flex-col overflow-y-hidden bg-boxdark-2 duration-300 ease-linear dark:bg-boxdark static translate-x-0"
      }
    >
      <header className="flex items-center justify-center gap-2 px-6 py-6">
      <a href='https://www.prabudental.com'><img className='w-32' src={require('./../../dashboard/dashboardheader/prabudental_transparent.png')}/></a>
        <RiArrowLeftSLine
          className="text-3xl font-semibold text-bodydark2 cursor-pointer"
          onClick={toggleDrawer}
        />
      </header>
      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        <nav className="py-4 px-4  lg:px-6">
          <div>
            <h3 className="mb-4  text-sm font-semibold text-bodydark2">MENU</h3>

            <ul className="mb-6 flex flex-col gap-1.5">
              <li className="text-white">
                <NavLink
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${
                    location.pathname === "/dashboard" ? "bg-graydark" : ""
                  }`}
                  to="/dashboard"
                >
                  <MdDashboard />
                  Dashboard
                </NavLink>
              </li>
              <li className="text-white">
                <NavLink
                  className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark focus:bg-graydark dark:hover:bg-meta-4  dark:bg-meta-4 ${
                    location.pathname === "/patientList" ? "bg-graydark" : ""
                  }`}
                  to="/patientList"
                >
                  <BiSolidDetail />
                  Patient Info
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </aside>
  );
};
