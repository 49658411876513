import React from "react";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Button, Input,ConfigProvider } from "antd";
import { Modal, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AWS from "aws-sdk";
import { useForm, Controller } from "react-hook-form"
import { ErrorMessage } from '@hookform/error-message';

AWS.config.update({
  accessKeyId: "AKIA2JPUCIF26KFB5QVU",
  secretAccessKey: "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",
  region: "ap-south-1",
});

const s3 = new AWS.S3();

export default function Diagnosis({
  initialPayloadData,
  payloadData,
  setPayloadData,
  setvalue,
  diagnosisFields,
  setDiagnosisFields,
  paramsfors3,
  id
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataRows, setDataRows] = useState(payloadData?.DataRows);
  const [buttonload, setbuttonload] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    setValue,
    getValues,
    control
  } = useForm();

  const { TextArea } = Input;
  const navigate = useNavigate();

  const callApi = async (payload) => {
    try {
      let res = await axios.post(
        "https://dev-prabudentalnodeapi.jetzerp.com/patient-new-record", payload
      );

      if (res?.status === 200) {
        setPayloadData(initialPayloadData);
        !!id
          ? toast.success("Patient Information Updated Successfully", {
              position: "top-right",
            })
          : toast.success("Patient Information Created Successfully", {
              position: "top-right",
            })
        setbuttonload(false);
        setTimeout(() => navigate('/patientList'), 2000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setbuttonload(false);
    }
  };

  const handleSubmitInfo = async () => {
    let payload = { ...payloadData, DataRows: diagnosisFields };
    console.log(paramsfors3);

    if (paramsfors3 !== null) {
      s3.upload(paramsfors3, (err, data) => {
        if (err) {
          console.error(err);
          return;
        }
        payload = { ...payload, General: [{ ...payload.General[0], patient_img: data.Location }]};

        callApi(payload);
      });
    } else {
      callApi(payload);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    clearErrors();
  }

  const handleSumbitTreatment = data => {
    setDiagnosisFields(prevState => [
      ...prevState,
      {...data, date: dayjs(new Date()).format("YYYY-MM-DD")}
    ]);
    setPayloadData((prevState) => ({
      ...prevState,
      DataRows: [
        ...prevState.DataRows,
        {...data, date: dayjs(new Date()).format("YYYY-MM-DD")}
      ],
    }));
    setDataRows((prevState) => [
      ...prevState,
      {...data, date: dayjs(new Date()).format("YYYY-MM-DD")}
    ]);
    setIsModalOpen(false);
    reset();
  }

  const handleNumberinput = (event) => {
    let inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/\D/g, '');

    if (inputValue !== sanitizedValue) {
      event.target.value = sanitizedValue;
      setValue('payment', sanitizedValue);
    } else {
      setValue('payment', sanitizedValue);
    }
    event.preventDefault();
  }

  return (
    <>
     <ConfigProvider
    theme={{
      components: {
      Button: {
        defaultHoverBorderColor:'#403E6F',
        //defaultHoverBg:'#403E6F',
      //  defaultHoverColor:'#403E6F',
        // Alias Token
        //colorBgContainer: '#f6ffed',
      },  
        }
    }}
  >
      <div
        className="patient-list-table "
        style={{
          maxHeight: 680,
          minHeight:250,
          marginLeft:"-20px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          overflow: "hidden",
        }}
      >
        <div className="flex justify-end gap-3 mb-2">
          <Button
            className="btn dia-add-btn"
            onClick={() => setIsModalOpen(true)}
          >
            Add
          </Button>
        </div>
        {dataRows.length > 0 ? (
          <div>
            <TableContainer
              component={Paper}
              style={{ width: "100%", height: "100%" }}
            >
              <Table stickyHeader aria-label="diagnosis table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Treatment</TableCell>
                    <TableCell align="center">Payment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataRows.map((row, index) => (
                    <TableRow key={index} className="table-row-hover">
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="center">{row.date}</TableCell>
                      <TableCell align="center">{row.treatment}</TableCell>
                      <TableCell align="center">{parseFloat(row.payment).toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <div className="flex justify-center border rounded w-[100%]"><TableContainer
          component={Paper}
          style={{ width: "100%", height: "100%" }} 
        >
          <Table stickyHeader aria-label="diagnosis table" className="min-h-[150px]">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Treatment</TableCell>
                <TableCell align="center">Payment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {dataRows.map((row, index) => (
                <TableRow key={index} className="table-row-hover">
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="center">{row.date}</TableCell>
                  <TableCell align="center">{row.treatment}</TableCell>
                  <TableCell align="center">{parseFloat(row.payment).toFixed(2)}</TableCell>
                </TableRow>
              ))} */}

              <TableRow>
                  <TableCell rowSpan={4} colSpan={4} className='relative' >
                    <div className=" absolute left-100 ml-65 bottom-4 flex flex-col">
                    <img className="w-13  ml-3" src={require('./folder.png')}/>
                <p className="">No data found</p></div> </TableCell>
                </TableRow>
            </TableBody>
            </Table>
          </TableContainer></div>
        )}
        <Modal
          open={isModalOpen}
          // onOk={modalok}
          onClose={handleCancel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-container"
        >
          <div className="modal w-[560px]">
            <div className="modal-header">
              <Typography className="text-md text-[#403E6F] font-semibold">
                Enter Treatment
              </Typography>
              <CancelIcon
                onClick={handleCancel}
                style={{
                  color: "#afafaf",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
            <form onSubmit={handleSubmit(handleSumbitTreatment)}>
              <div className="modal-body">
                <label>Treatment</label>
                <Controller
                  control={control}
                  name="treatment"
                  rules={{
                    required: 'Treatment field is required'
                  }}
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      name="treatment"
                      status={errors.treatment ? 'error' : null}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="treatment"
                  render={({ message }) => (
                    <p className="text-red-500 text-sm mt-1">{message}</p>
                  )}
                />
                <label>Payment</label>
                <Controller
                  control={control}
                  name="payment"
                  rules={{
                    required: 'Payment field is required'
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      name="payment"
                      onInput={e => handleNumberinput(e)}
                      status={errors.payment ? 'error' : null}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="payment"
                  render={({ message }) => (
                    <p className="text-red-500 text-sm mt-1">{message}</p>
                  )}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn save-btn"
                  onClick={handleSubmit(handleSumbitTreatment)}
                >
                  Save
                </button>
                <button
                  className="btn close-btn"
                  type="button"
                  onClick={handleCancel}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
      <div className="flex justify-center gap-3 mt-2">
        <span></span>
        <button
          className="btn close-btn"
          onClick={() => {
            setvalue(1);
          }}
        >
          Back
        </button>
        <Button
          loading={buttonload}
          className="btn save-btn"
          onClick={handleSubmitInfo}
        >
          Submit
        </Button>
      </div>
      <ToastContainer/>
      </ConfigProvider>
    </>
  );
}
