import React, { useEffect, useState } from "react";
import { Sidebar } from "./sidebar/sidebar";
import { Dashboardheader } from "./dashboardheader/Dashboardheader";
import { Outlet } from "react-router-dom";

export const Dashboardlayout = ({toggleValue, setToggleValue}) => {

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  //const[toggleValue, setToggleValue] = useState(localStorage.getItem('toggleValue'));


  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className={`${toggleValue === false ?'dark bg-[#1a222c]':""}`}>
      <div className="flex h-screen overflow-hidden">
        <div
          className={`ease-in-out ${isDrawerOpen ? "translate-x-0" : "hidden"}`}
        >
          <Sidebar isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
        </div>
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          <Dashboardheader
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            toggleValue={toggleValue}
            setToggleValue={setToggleValue}
          />
          <main className="flex-grow">
            <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
            <Outlet toggleValue={toggleValue} />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
