import React from "react";
import { useState, useEffect, useRef } from "react";
import { EventHubConsumerClient } from "@azure/event-hubs";
import { Select, ConfigProvider } from "antd";
import { FiRefreshCw } from "react-icons/fi";
import { DateRange } from "react-date-range";
import axios from "axios";
import dayjs from "dayjs";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Button } from "antd";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import { BsExclamationLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
export const Details = ({toggleValue}) => {
  const today = new Date();
  const containerRef = useRef(null);
  const navigate = useNavigate();
  // const onChange: DatePickerProps<Dayjs[]>['onChange'] = (date, dateString) => {
  //   console.log(date, dateString);
  // };

  const [totaleventdata, settotaleventdata] = useState({scannedData:[],uploadedData:[]});
  const [successeventdata, setsuccesseventdata] = useState({scannedData:[],uploadedData:[]});
  const [failedeventdata, setfailedeventdata] = useState({scannedData:[],uploadedData:[]});
  const [apidata, setapidata] = useState({scannedData:[],uploadedData:[]});
  const [date, setdate] = useState(1);
  const [startDate, setStartDate] = useState(dayjs(today).format("YYYY-MM-DD"));
  const [EndDate, setEndDate] = useState(dayjs(today).format("YYYY-MM-DD"));
  const [showbutton, setshowbutton] = useState(false);
  const [showdatepicker, setshowdatepicker] = useState(false);
  const [initialdaterange, setInitialDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    getinitialdata();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {

    const ScannedfilteredData = apidata['scannedData'].filter((item) => {
      const itemDate = dayjs(item.Created_At).format("YYYY-MM-DD");
      return itemDate >= startDate && itemDate <= EndDate;
    });

    const UploadedfilteredData = apidata['uploadedData'].filter((item) => {
      const itemDate = dayjs(item.Created_At).format("YYYY-MM-DD");
      return itemDate >= startDate && itemDate <= EndDate;
    }); 


    settingdata(ScannedfilteredData,UploadedfilteredData);

  }, [startDate, EndDate, date, apidata]);

  useEffect(() => {
    console.log(apidata,'apidata');  },[apidata]);

  useEffect(() => {
    try {
      const event = new EventHubConsumerClient(
        EventHubConsumerGroup,
        EventHubConnectionString,
        EventHubName
      );

      const subscriber = event.subscribe({
        processEvents: async (events) => {
          console.log("event", events);

          if (events.length > 0 && events[0].body) {

            if (events[0].body['actionKey'] === "scanned") {
              setapidata((prev) => ({ ...prev, 'scannedData': [...prev['scannedData'], events[0].body] }));
            }

            else{
              setapidata((prev) => ({ ...prev, 'uploadedData': [...prev['uploadedData'], events[0].body] }));
            }

          }
        },

        processError: (err) => {
          console.log("error", err);
        },

      });

      return () => {
        subscriber.close();
        event.close();
      };
      
    } catch (err) {
      console.log("error", err);
    }
  }, []);

  const dateOnChange = (key) => {
    setdate(key);

    if (key !== 6) {
      const [fromDate, toDate] = getTimeRange(key);
      setStartDate(fromDate);
      setEndDate(toDate);
    } 
    
    else {
      setshowdatepicker(true);
      setStartDate(dayjs(new Date()).format("YYYY-MM-DD"));
      setEndDate(dayjs(new Date()).format("YYYY-MM-DD"));
      setInitialDateRange([
        { startDate: new Date(), endDate: new Date(), key: "selection" },
      ]);
    }

  };

  const settingdata = async (scanneddata, uploadeddata) => {

    if (scanneddata) {

      const scannedsuccess = scanneddata.filter((itm) => itm.Type === "success");
      setsuccesseventdata((prev) => ({ ...prev, 'scannedData': scannedsuccess }));

      const scannedfailed = scanneddata.filter((itm) => itm.Type === "failed");
      setfailedeventdata((prev) => ({ ...prev, 'scannedData': scannedfailed }));

      const scannedtotal = scanneddata.filter((itm) => itm.Type === "success" || itm.Type === "failed");
      settotaleventdata((prev) => ({ ...prev, 'scannedData': scannedtotal }));

    }

    if (uploadeddata) {

      const uploadedsuccess = uploadeddata.filter((itm) => itm.Type === "success");
      setsuccesseventdata((prev) => ({ ...prev, 'uploadedData': uploadedsuccess }));

      const uploadedfailed = uploadeddata.filter((itm) => itm.Type === "failed");
      setfailedeventdata((prev) => ({ ...prev, 'uploadedData': uploadedfailed }));

      const uploadedtotal = uploadeddata.filter((itm) => itm.Type === "success" || itm.Type === "failed");
      settotaleventdata((prev) => ({ ...prev, 'uploadedData': uploadedtotal }));

    }

  };


  // function toLocal(date) {
  //   let Offset;
  //   const currentOffset = new Date().getTimezoneOffset();
  //   if (currentOffset === -330) {
  //     Offset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
  //   } else if (currentOffset === 300) {
  //     Offset = -5 * 60 * 60 * 1000; // EST offset in milliseconds
  //   } else {
  //     Offset = 0;
  //   }

  //   let formatteddate = "";

  //   if (date.includes("Z")) {
  //     formatteddate = dayjs(date);
  //   } else {
  //     formatteddate = dayjs(date, "DD-MM-YYYY HH:mm:ss").add(
  //       Offset,
  //       "milliseconds"
  //     );
  //   }
  //   return dayjs(formatteddate);
  // }

  function DateRangeFunction(e) {

    setshowbutton(true);
    var sdate = new Date(e[0].startDate),
      smnth = ("0" + (sdate.getMonth() + 1)).slice(-2),
      sday = ("0" + sdate.getDate()).slice(-2);

    var edate = new Date(e[0]?.endDate),
      emnth = ("0" + (edate.getMonth() + 1)).slice(-2),
      eday = ("0" + edate.getDate()).slice(-2);

    const formattedDates = [
      [sdate.getFullYear(), smnth, sday].join("-"),
      [edate.getFullYear(), emnth, eday].join("-"),
    ];

    const [fromDate, toDate] = formattedDates;

    const ScannedfilteredData = apidata['scannedData'].filter((uv) => {
      const dateTime = dayjs(uv.Created_At).format("YYYY-MM-DD");
       console.log(dateTime, fromDate,toDate,'custom date');
      return (
        dayjs(dateTime).format("YYYY-MM-DD") >= fromDate &&
        dayjs(dateTime).format("YYYY-MM-DD") <= toDate
      );
    });

    const UploadedfilteredData = apidata['uploadedData'].filter((uv) => {
      const dateTime = dayjs(uv.Created_At).format("YYYY-MM-DD");

      return (
        dayjs(dateTime).format("YYYY-MM-DD") >= fromDate &&
        dayjs(dateTime).format("YYYY-MM-DD") <= toDate
      );
    });

  

    settingdata(ScannedfilteredData,UploadedfilteredData);
    setStartDate(fromDate);
    setEndDate(toDate);
  }

  function handleClick(e) {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setshowdatepicker(false);
    }
  }


  const getinitialdata = async () => {

    const res = await axios.get(
      "https://dev-prabudentalnodeapi.jetzerp.com/get-logs"
    );
   
    setapidata(res.data);

    const ScannedfilteredData = res.data['scannedData'].filter((item) => {
      const itemDate = dayjs(item.Created_At).format("YYYY-MM-DD");
      return itemDate >= startDate && itemDate <= EndDate;
    });

    const UploadedfilteredData = res.data['uploadedData'].filter((item) => {
      const itemDate = dayjs(item.Created_At).format("YYYY-MM-DD");
      return itemDate >= startDate && itemDate <= EndDate;
    }); 
    
    settingdata(ScannedfilteredData,UploadedfilteredData);

    }


  function getTimeRange(id) {
    let from;
    let to;
    if (id === 1) {
      //today
      from =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        today.getDate().toString().padStart(2, "0");
      to =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        today.getDate().toString().padStart(2, "0");
    }
    if (id === 2) {
      //yesterday
      const date = new Date();
      date.setDate(date.getDate() - 1);
      from =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0");
      to =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0");
    } else if (id === 3) {
      //7days or week
      const date = new Date();
      date.setDate(date.getDate() - 7);
      from =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0");
      to =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        today.getDate().toString().padStart(2, "0");
    }
    // else if (id === 4) {
    //   //last30 days
    //   const date = new Date();
    //   date.setDate(date.getDate() - 30);
    //   from =
    //     date.getFullYear() +
    //     "-" +
    //     (date.getMonth() + 1).toString().padStart(2, "0") +
    //     "-" +
    //     date.getDate().toString().padStart(2, "0");
    //   to =
    //     today.getFullYear() +
    //     "-" +
    //     (today.getMonth() + 1).toString().padStart(2, "0") +
    //     "-" +
    //     today.getDate().toString().padStart(2, "0");
    // }
    else if (id === 4) {
      //thismonth
      const date = new Date();
      date.setDate(1);
      from =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0");
      to =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        today.getDate().toString().padStart(2, "0");
    } else if (id === 5) {
      //thisyear
      const date = new Date();
      date.setDate(1);
      date.setMonth(1);
      from =
        date.getFullYear() +
        "-" +
        date.getMonth().toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0");
      to =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
          .getDate()
          .toString()
          .padStart(2, "0");
    }
    return [from, to];
  }

  const EventHubCreds = {
    EventHubConnectionString:
      "Endpoint=sb://prabhudentaleventhub.servicebus.windows.net/;SharedAccessKeyName=RootManageSharedAccessKey;SharedAccessKey=Ml1Fem+MonDJPnkA/8BDICq5bTuq5fRmB+AEhNOCpsU=",
    EventHubName: "devprabudental",
    EventHubConsumerGroup: "$Default",
  };

  const {
    EventHubConnectionString,
    EventHubName,
    EventHubConsumerGroup
  } = EventHubCreds;

  return (
    <>
    <ConfigProvider
        theme={{
          components: {
            Select: {
             colorBgContainer:toggleValue ? 'white' : '#33363a',
             colorPrimary:'#d9d9d9',             
             colorPrimaryHover:'#d9d9d9',
             optionActiveBg:toggleValue ?'#7876B2': "#262525",
              // colorBgBase:'red'             
            //  colorBorderSecondary:toggleValue ?'gray':'#33363a',
              colorText:toggleValue ? 'black' :'white',
              colorBgElevated:toggleValue ? 'white' : '#33363a',
           controlItemBgActive:toggleValue ? 'none': '#212122',
            },
            Button: {
              colorPrimaryHover: '#24303f'
            }
          }
        }}
      >
      <div
        style={{ boxShadow: "0px 0px 8.899999618530273px 0px #00000017" }}
        className="mb-4 flex justify-start items-end gap-4 xl:p-3 rounded-xl border bg-white p-4  dark:border-strokedark dark:bg-[#24303f] md:p-6"
        >
        <div className="flex flex-col">
          <label className="text-sm font-medium dark:text-white">Preset</label>
          <Select
            className="mt-2 text-red dark:text-white dark:bg-[#24303f]"
            type="primary"
            defaultValue="Today"
            style={{ width: 300 }}
            onSelect={dateOnChange}
            value={date}
            options={[
              { label: <span>Today</span>, value: 1 },
              { label: <span>Yesterday</span>, value: 2 },
              { label: <span>Last Week</span>, value: 3 },
              { label: <span>This Month</span>, value: 4 },
              { label: <span>This Year</span>, value: 5 },
              { label: <span>Custom Date</span>, value: 6 },
            ]}
          />
        </div>
        {date === 6 && (
          <div
          // style={{
          //   width: "13%",
          // }}
          >
              <Button onClick={() => dateOnChange(6)} className="dark:text-white" >
              {`${dayjs(initialdaterange[0].startDate).format(
                "DD-MM-YYYY"
              )} - ${dayjs(initialdaterange[0].endDate).format("DD-MM-YYYY")}`}
            </Button>
          </div>
        )}

        <div
          style={{
            padding: "6px 10px",
            border: "1px solid #255C81",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          <span>
            <FiRefreshCw
              style={{ color: "#255C81" }}
              onClick={() => dateOnChange(1)}
            />
          </span>
        </div>
        {showdatepicker && (
          <>
            <div style={{ position: "relative" }}>
              <div
                ref={containerRef}
                className="calender-pl"
                style={{
                  marginTop: "-14em",
                  marginLeft: "18px",
                  position: "absolute",
                  left: "-36.8em",
                  top: "14.5em",
                  zIndex: "99",
                  border: "1px solid #ddd",
                }}
              >
                <DateRange
                    className="date-range-picker dark:bg-gray text-white"
                    showSelectionPreview
                    rangeColors={["#24303f"]}
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  onChange={(item) => {
                    setInitialDateRange([item.selection]);
                    DateRangeFunction([item.selection]);
                  }}
                  ranges={initialdaterange}
                ></DateRange>
              </div>
            </div>
          </>
        )}
      </div>
      {/* <h1 className="text-lg font-medium">Scanned Data</h1> */}

      <div className="2xl:gap-7.5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:gap-6 xl:grid-cols-3">
        
        <div
          style={{ boxShadow: "0px 0px 8.899999618530273px 0px #00000017" }}
          className="flex items-center justify-between xl:p-3 rounded-xl border bg-white p-4 cursor-pointer dark:border-strokedark dark:bg-boxdark md:p-6"
          onClick={() => navigate('/dashboard/logdetail', {
            state: {
              key: 1,
              data: totaleventdata["uploadedData"],

            }
          })}
        >
          <span>
          <h4 className="text-sm font-medium dark:text-white">Total Count</h4>
            <h4 className="text-2xl text-black dark:text-white mt-2">
              {totaleventdata["uploadedData"].length}
            </h4>
          </span>
          <span className="flex h-[2.6rem] w-[2.625rem] items-center justify-center rounded-lg bg-primary"><FaArrowRightLong className="status_icon" /></span>
          {/* <div><FaCircle className="text-3xl" style={{color:'#D9D9D9'}}/></div> */}
        </div>

        <div
          style={{ boxShadow: "0px 0px 8.899999618530273px 0px #00000017" }}
          className="flex items-center justify-between xl:p-3 rounded-xl border bg-white p-4 cursor-pointer dark:border-strokedark dark:bg-boxdark md:p-6"
          onClick={() => navigate('/dashboard/logdetail', {
            state: {
              key: 2,
              data: successeventdata['scannedData'],


            }
          })}
        >
          <span>
          <h4 className="text-sm font-medium dark:text-white">Success Count</h4>
            <h4 className="text-2xl text-black dark:text-white mt-2">
              {successeventdata['scannedData'].length}
            </h4>
          </span>
          <span className="flex h-[2.6rem] w-[2.625rem] items-center justify-center rounded-lg bg-success"><FaCheck className="status_icon"/></span>
          {/* <div><FaCircle className="text-3xl" style={{color:'#D9D9D9'}}/></div> */}
        </div>

        <div
          style={{ boxShadow: "0px 0px 8.899999618530273px 0px #00000017" }}
          className="flex items-center justify-between xl:p-3 rounded-xl border bg-white p-4 cursor-pointer dark:border-strokedark dark:bg-boxdark md:p-6"
          onClick={() => navigate('/dashboard/logdetail', {
            state: {
              key: 3,
              data: failedeventdata['scannedData']
            }
          })}
        >
            <span>
            <h4 className="text-sm font-medium dark:text-white">Failure Count</h4>
            <h4 className="text-2xl text-black dark:text-white mt-2">
              {failedeventdata['scannedData'].length}
            </h4>
            </span>
            <span className="flex h-[2.6rem] w-[2.625rem] items-center justify-center rounded-lg bg-danger"><BsExclamationLg className="status_icon"/></span>
          {/* <div><FaCircle className="text-3xl" style={{color:'#D9D9D9'}}/></div> */}
        </div>
     </div>
<br/>





     {/* <h1 className="text-lg font-medium">Uploaded Data</h1>
    <div className="2xl:gap-7.5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:gap-6 xl:grid-cols-3">
        <div
          style={{ boxShadow: "0px 0px 8.899999618530273px 0px #00000017" }}
          className="flex items-center justify-between xl:p-3 rounded-xl border bg-white p-4 cursor-pointer dark:border-strokedark dark:bg-boxdark md:p-6"
          onClick={() => navigate('/dashboard/logdetail', {
            state: {
              key: 1,
              data: totaleventdata['uploadedData']
            }
          })}
        >
          <span>
            <h4 className="text-sm font-medium">Total Count</h4>
            <h4 className="text-2xl text-black dark:text-white mt-2">
              {totaleventdata['uploadedData'].length}
            </h4>
          </span>
          <span className="flex h-[2.6rem] w-[2.625rem] items-center justify-center rounded-lg bg-primary"><FaArrowRightLong className="status_icon"/></span>
          <div><FaCircle className="text-3xl" style={{color:'#D9D9D9'}}/></div>
        </div>
        <div
          style={{ boxShadow: "0px 0px 8.899999618530273px 0px #00000017" }}
          className="flex items-center justify-between xl:p-3 rounded-xl border bg-white p-4 cursor-pointer dark:border-strokedark dark:bg-boxdark md:p-6"
          onClick={() => navigate('/dashboard/logdetail', {
            state: {
              key: 2,
              data: successeventdata['uploadedData']
            }
          })}
        >
          <span>
            <h4 className="text-sm font-medium">Success Count</h4>
            <h4 className="text-2xl text-black dark:text-white mt-2">
              {successeventdata['uploadedData'].length}
            </h4>
          </span>
          <span className="flex h-[2.6rem] w-[2.625rem] items-center justify-center rounded-lg bg-success"><FaCheck className="status_icon"/></span>
          <div><FaCircle className="text-3xl" style={{color:'#D9D9D9'}}/></div>
        </div>
        <div
          style={{ boxShadow: "0px 0px 8.899999618530273px 0px #00000017" }}
          className="flex items-center justify-between xl:p-3 rounded-xl border bg-white p-4 cursor-pointer dark:border-strokedark dark:bg-boxdark md:p-6"
          onClick={() => navigate('/dashboard/logdetail', {
            state: {
              key: 3,
              data: failedeventdata['uploadedData']
            }
          })}
        >
            <span>
            <h4 className="text-sm font-medium">Failure Count</h4>
            <h4 className="text-2xl text-black dark:text-white mt-2">
              {failedeventdata['uploadedData'].length}
            </h4>
            </span>
            <span className="flex h-[2.6rem] w-[2.625rem] items-center justify-center rounded-lg bg-danger"><BsExclamationLg className="status_icon"/></span>
          <div><FaCircle className="text-3xl" style={{color:'#D9D9D9'}}/></div>
        </div>
      </div> */}





      {/* <div className="mt-4 flex justify-start items-center gap-4">
        <div
          style={{ boxShadow: "0px 0px 8.899999618530273px 0px #00000017" }}
          className="w-full xl:p-3 rounded-xl border bg-white p-4  dark:border-strokedark dark:bg-boxdark md:p-6"
        >
          <BarChart
            // width={500}
            height={300}
            series={[
              { data: pData, label: "pv", id: "pvId" },
              { data: uData, label: "uv", id: "uvId" },
            ]}
            xAxis={[{ data: xLabels, scaleType: "band" }]}
          />
        </div>
        <div
          style={{ boxShadow: "0px 0px 8.899999618530273px 0px #00000017" }}
          className="w-full xl:p-3 rounded-xl border bg-white p-4  dark:border-strokedark dark:bg-boxdark md:p-6"
        >
          <BarChart
            // width={500}
            height={300}
            series={[
              { data: pData, label: "pv", id: "pvId" },
              { data: uData, label: "uv", id: "uvId" },
            ]}
            xAxis={[{ data: xLabels, scaleType: "band" }]}
          />
        </div>
      </div> */}
            </ConfigProvider>

    </>
  );
};
