import React, { useEffect, useMemo, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { EyeOutlined } from '@ant-design/icons';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Switch, ConfigProvider } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import FaceDetector from "./FaceDetection";

import dayjs from "dayjs";
const customTheme = createTheme({
  palette: {
    primary: {
      main: '#403e6f',
    },
  },
});

export default function PatientList({ toggleValue }) {
  const [tableData, setTableData] = useState([]);
  const [backupData, setBackupData] = useState([]);
  const [isCameraModalOpen, setIsCameraModalOpen] = useState(false);
  const [restore_patient, setrestore_patient] = useState({ modal_open: false, patient_details: {} })
  const [isOpened, setIsOpened] = useState(false);
  const [lastpatientid, setlastpatientid] = useState();
  const [searchedValue, setSearchedValue] = useState("");
  const [isloading, setloading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [diagnosis_data, setdiagnosis_data] = useState([]);
  const [patdelpop, setpatdelpop] = useState(false);
  const [delpatientid, setdelpatientid] = useState();
  const [patientIdfromFaceDetection, setPatientIdfromFaceDetection] = useState(null);

  const navigate = useNavigate();
  console.log('restore_patient', restore_patient)
  function increaseSequence(sequence) {
    const numericPart = parseInt(sequence.slice(2));
    const incrementedNumericPart = numericPart + 1;
    const paddedNumericPart = String(incrementedNumericPart).padStart(3, '0');
    const newSequence = sequence[0] + sequence[1] + paddedNumericPart;
    return newSequence;
  }

  async function getpatientdetails() {
    try {
      setloading(true)
      const res = await axios.get(
        "https://dev-prabudentalnodeapi.jetzerp.com/get-all-patient-details"
      );

      setloading(false);
      setTableData(res.data.data.patientDetails.sort((a, b) => b.SNO - a.SNO));
      setBackupData(res.data.data.patientDetails.sort((a, b) => b.SNO - a.SNO));
      setlastpatientid(increaseSequence(res.data.data.lastPatientId));
    } catch (error) {
      console.log(error);
      setloading(false);
      setTableData([]);
      setBackupData([]);
      setlastpatientid(null);
    }

    try {
      const res = await axios.get('https://dev-prabudentalnodeapi.jetzerp.com/get-inactive-patient-details')
      setrestore_patient((prev) => ({ ...prev, patient_details: res?.data.patientDetails }))
    }

    catch (error) {
      console.log(error)
    }
  }

  async function viewing_patient_diagnosis(patient_id) {
    try {
      const res = await axios.get(
        `https://dev-prabudentalnodeapi.jetzerp.com/get-patient-detail/${patient_id}`
      );
      console.log(res.data.data.patientPrescription, "viewing_patient_diagnosis");
      setdiagnosis_data(res.data.data.patientPrescription);
      setIsModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function delete_patient(patient_id) {
    setpatdelpop(true);
    setdelpatientid(patient_id)
  }

  const confirmdel = async () => {
    const res = await axios.post(
      `https://dev-prabudentalnodeapi.jetzerp.com/delete-patient-detail/${delpatientid}`
    );
    setpatdelpop(false);
    getpatientdetails()
    console.log(res, "delete_patient");
  }

  useEffect(() => {
    console.log(lastpatientid, "lastpatientid");
  }, [lastpatientid]);

  useEffect(() => {
    if (searchedValue.length > 0) {
      const searchedData = backupData.filter(
        (item) =>
          item?.idno?.toLocaleLowerCase()?.includes(searchedValue?.toLocaleLowerCase()) ||
          item?.name?.toLocaleLowerCase()?.includes(searchedValue?.toLocaleLowerCase()) ||
          item?.date_of_birth?.toLocaleLowerCase()?.includes(searchedValue?.toLocaleLowerCase()) ||
          item?.mobile?.toLocaleLowerCase()?.includes(searchedValue?.toLocaleLowerCase()) ||
          item?.age?.toString()?.toLocaleLowerCase()?.includes(searchedValue?.toLocaleLowerCase()) ||
          item?.blood_group?.toLocaleLowerCase()?.includes(searchedValue?.toLocaleLowerCase()) ||
          item?.email?.toLocaleLowerCase()?.includes(searchedValue?.toLocaleLowerCase())
      );
      setTableData(searchedData);
    } else if (patientIdfromFaceDetection) {
      const searchedData = backupData.filter((item) =>
        item?.Patient_Id?.includes(patientIdfromFaceDetection)
      );
      setTableData(searchedData);
      console.log("FACEFILTER", searchedData, patientIdfromFaceDetection);
    } else {
      setTableData(backupData);
    }
  }, [searchedValue, patientIdfromFaceDetection]);

  useEffect(() => {
    let allow = true;
    try {
      if (allow) {
        getpatientdetails();
      }
    } catch (error) {
      console.log(error, "error");
    }

    return () => {
      allow = false;
    };
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "idno",
        header: "Patient_Id",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "date_of_birth",
        header: "Date of Birth",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => {
          return <span>{row.original.date_of_birth}</span>;
          // return <span>{dayjs(new Date(row.original.Date_of_Birth)).format("DD-MM-YYYY")}</span>;
        }
      },
      {
        accessorKey: "mobile",
        header: "Mobile",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        // Cell: ({ row }) => {
        //   return <span>{row.original.number}</span>;
        // },
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      // {
      //   accessorKey: "Age",
      //   header: "Age",
      //   size: 150,
      //   muiTableHeadCellProps: {
      //     align: "left",
      //   },
      //   muiTableBodyCellProps: {
      //     align: "left",
      //   },
      // },
      // {
      //   accessorKey: "Blood_Group",
      //   header: "Blood Group",
      //   size: 150,
      //   muiTableHeadCellProps: {
      //     align: "left",
      //   },
      //   muiTableBodyCellProps: {
      //     align: "left",
      //   },
      //   filterVariant: "select",
      //   filterSelectOptions: [
      //     { label: "Select", value: "" },
      //     { label: "A Positive", value: "a+ve" },
      //     { label: "A Negative", value: "a-ve" },
      //     { label: "B Positive", value: "b+ve" },
      //     { label: "B Negative", value: "b-ve" },
      //     { label: "O Positive", value: "o+ve" },
      //     { label: "O Negative", value: "o-ve" },
      //   ],
      // },

      {
        accessorKey: 'diagnosis',
        header: 'Diagnosis',
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        size: 150,
        Cell: ({ row }) => {
          return (<EyeOutlined className="text-[#403e6f]" style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }} onClick={() => { viewing_patient_diagnosis(row.original.idno) }} />)
        },
        enableColumnFilter: false,
      },
      {
        accessorKey: "action",
        header: "Action",
        muiTableHeadCellProps: {
          align: "center",
        },
        size: 150,
        muiTableBodyCellProps: {
          align: "center",
        },
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <div className="flex justify-center gap-7">
              <EditOutlined className="text-[#403e6f]"
                onClick={() =>
                  navigate(`/patientInfo/${row.original.idno}`, { state: { lastpatientid } })
                }
              />
              <DeleteOutlined className="text-red-500" onClick={() => delete_patient(row.original.idno)} />
            </div>
          );
        },
      },
    ],
    [tableData]
  );

  const diagnosis_columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => {
          return <span>{row.index + 1}</span>
        }
      },

      {
        accessorKey: "date",
        header: "Date",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },

      {
        accessorKey: "treatment",
        header: "Treatment",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "payment",
        header: "Payment",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
    ], [diagnosis_data]
  );

  const restore_patient_columns = useMemo(
    () => [
      {
        accessorKey: "idno",
        header: "Patient Id",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => {
          return <span>{row?.original?.idno}</span>
        }
      },

      {
        accessorKey: "name",
        header: "Patient Name",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => {
          return <span>{row?.original?.name}</span>
        }
      },

      {

        header: "Active",
        size: 150,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          const handle_switch = async (checked) => {
            console.log(checked, 'chk')
            if (checked) {
              const res = await axios.post(`https://dev-prabudentalnodeapi.jetzerp.com/restore-patient-detail/${row.original?.idno}`)
              console.log(res, 'res')
              getpatientdetails()
            }
          }
          return (
            <>
              <Switch key={row.original?.idno} defaultChecked={row.original?.idno ? false : true} className="bg-[#E0E0E0]" onChange={handle_switch} />
            </>
          )

        }
      }

    ], [restore_patient]
  );

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableTopToolbar: true,
    enableColumnResizing: true,
    enableColumnFilter: false,
    searchIconVisible: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    // enableHiding: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    initialState: { density: "comfortable", pagination: { pageSize: 10 } },
    state: {
      isLoading: isloading
    },
    layoutMode: "semantic",
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 50],
    },
    muiTableBodyRowProps: () => ({
      sx: { cursor: "pointer" },
    }),
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(even) > td': {
          backgroundColor: '#f0f4f7',

        },
        // '& tr:nth-of-type(1) > td': {
        //   border:'none'
        // },


      },
    },
    muiTableBodyCellProps: {
      sx: {
        // borderRight: 'none',
        border: 'none',
        //add a border between columns
      },
    },
    muiTableHeadCellProps: {
      sx: (theme) => ({
        fontSize: '14px',
        fontWeight: 'bold',
        border: 'none',
        backgroundColor: '#f0f4f7',

        //add a border between columns
      }),
    },

    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#ffff',

      }
    },
    muiTableFooterCellProps: {
      sx: {
        border: 'none',
      }
    }
  });

  const diagnosis_table = useMaterialReactTable({
    columns: diagnosis_columns,
    data: diagnosis_data,
    enableTopToolbar: true,
    enableColumnResizing: true,
    enableColumnFilter: false,
    searchIconVisible: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    initialState: { density: "compact", pagination: { pageSize: 10 } },
    layoutMode: "semantic",
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 50],
    },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(even) > td': {
          backgroundColor: '#f0f4f7',

        },
        // '& tr:nth-of-type(1) > td': {
        //   border:'none'
        // },


      },
    },
    muiTableBodyCellProps: {
      sx: {
        // borderRight: 'none',
        border: 'none',
        //add a border between columns
      },
    },
    muiTableHeadCellProps: {
      sx: (theme) => ({
        fontSize: '14px',
        fontWeight: 'bold',
        border: 'none',
        backgroundColor: '#f0f4f7',

        //add a border between columns
      }),
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#ffff',

      }
    },
    muiTableFooterCellProps: {
      sx: {
        border: 'none',
      }
    }
  });

  const restore_patient_table = useMaterialReactTable({
    columns: restore_patient_columns,
    data: restore_patient.patient_details,
    enableTopToolbar: true,
    enableColumnResizing: true,
    enableColumnFilter: false,
    searchIconVisible: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    enableBottomToolbar: false,
    initialState: { density: "compact", pagination: { pageSize: 10 } },
    layoutMode: "semantic",
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 50],
    },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(even) > td': {
          backgroundColor: '#f0f4f7',

        },
        // '& tr:nth-of-type(1) > td': {
        //   border:'none'
        // },
      },
    },

    muiTableBodyCellProps: {
      sx: {
        border: 'none',
        //add a border between columns
      },
    },
    muiTableHeadCellProps: {
      sx: (theme) => ({
        fontSize: '14px',
        fontWeight: 'bold',
        border: 'none',
        backgroundColor: '#f0f4f7',

        //add a border between columns
      }),
    },

    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#ffff',

      }
    },
    muiTableFooterCellProps: {
      sx: {
        border: 'none',
      }
    }
  });

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              defaultHoverBorderColor: '#403E6F',
              //defaultHoverBg:'#403E6F',
              defaultHoverColor: '#403E6F',
              // Alias Token
              //colorBgContainer: '#f6ffed',
            },
            Switch: {
              colorPrimary: '#403E6F',
              colorPrimaryHover: '#403E6F'
            }
          }
        }}
      >
        <div className="flex justify-between align-center">
          <h4
            style={{ marginBottom: "-10px" }}
            className="text-[#403E6F] text-lg font-semibold dark:text-white"
          >
            Patient List{" "}
          </h4>
          <div className="flex gap-3">
            <Button
              className="btn save-btn"
              style={{ marginBottom: "-10px" }}
              onClick={() => {
                setIsCameraModalOpen(true);
                setIsOpened(true);
              }}
            // onClick={()=>navigate("/facedetection")}
            >
              Identify by face
            </Button>
            <Button
              className="text-[#403E6F] border border-[#403E6F]  hover:text-white"
              onClick={() => {
                navigate(`/patientInfo`, { state: { lastpatientid, backupData } });
              }}
            >
              Create Patient
            </Button>

            <Button
              className="text-[#403E6F] border border-[#403E6F]  hover:text-white"
              onClick={() => { setrestore_patient((prev) => ({ ...prev, modal_open: true })) }}
            >
              Restore Patient
            </Button>
          </div>

        </div>
        <div
          style={{
            display: "flex",
            position: "relative",
            top: "15px",
            gap: "10px",
          }}
        >
          <div className="searchbar">
            <Input
              placeholder="Search"
              style={{ width: "220px" }}
              prefix={
                <SearchOutlined style={{ fontSize: "20px", color: "#D3D2D6" }} />
              }
              onChange={(e) => setSearchedValue(e.target.value.trim())}
            />
          </div>
        </div>
        <Modal
          open={isModalOpen}
          onClose={() => { setIsModalOpen(false) }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-container"
        >
          <div className="modal">
            <div className="modal-header">
              <Typography className="text-md font-medium text-[#403E6F]">
                Diagnosis
              </Typography>
              <CancelIcon
                onClick={() => setIsModalOpen(false)}
                style={{
                  color: "#afafaf",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
            <div className="modal-body">
              {/* <FaceDetector
              isOpened={isOpened}
              setIsCameraModalOpen={setIsCameraModalOpen}
            /> */}
              <MaterialReactTable table={diagnosis_table} />
            </div>
            < div className="modal-footer">
              <button
                className="btn close-btn"
                onClick={() => setIsModalOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>


        <Modal
          open={restore_patient.modal_open}
          onClose={() => { setrestore_patient((prev) => ({ ...prev, modal_open: false })) }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-container"
        >
          <div className="modal">
            <div className="modal-header">
              <Typography className="text-md font-medium text-[#403E6F]">
                Restore Patient
              </Typography>
              <CancelIcon
                onClick={() => setrestore_patient((prev) => ({ ...prev, modal_open: false }))}
                style={{
                  color: "#afafaf",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
            <div className="modal-body">
              {/* <FaceDetector
              isOpened={isOpened}
              setIsCameraModalOpen={setIsCameraModalOpen}
            /> */}
              <MaterialReactTable table={restore_patient_table} />
            </div>
            < div className="modal-footer">
              <button
                className="btn close-btn"
                onClick={() => setrestore_patient((prev) => ({ ...prev, modal_open: false }))}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          open={patdelpop}
          onClose={() => { setpatdelpop(false) }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-container"
        >
          <div className="modal">
            <div className="modal-header">
              <Typography className="text-md font-medium text-[#403E6F]">
                Delete Patient
              </Typography>
              <CancelIcon
                onClick={() => setpatdelpop(false)}
                style={{
                  color: "#afafaf",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
            <div className="modal-body">
              {/* <FaceDetector
              isOpened={isOpened}
              setIsCameraModalOpen={setIsCameraModalOpen}
            /> */}
              <p className="text-md">
                Are you sure you want to delete this patient?
              </p>
            </div>
            < div className="modal-footer">
              <button
                className="btn yes-btn"
                onClick={() => confirmdel()}
              >
                Yes
              </button>
              <button
                className="btn close-btn"
                onClick={() => setpatdelpop(false)}
              >
                Back
              </button>
            </div>
          </div>
        </Modal>
        <ThemeProvider theme={customTheme}>
          <div className="patient-table">
            <MaterialReactTable
              table={table}
              state={{ isLoading: isloading }}
              muiCircularProgressProps={{
                color: '#403e6f',
                thickness: 5,
                size: 55,
              }}
              muiSkeletonProps={{
                animation: 'pulse',
                height: 28,
              }}
            />
          </div>
          {!!patientIdfromFaceDetection && (
            <div className="mt-2 flex justify-center">
              <button
                className="btn save-btn"
                onClick={() => setPatientIdfromFaceDetection(null)}
              >
                Back
              </button>
            </div>
          )}

        </ThemeProvider>
        <Modal
          open={isCameraModalOpen}
          onClose={() => setIsOpened(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-container"
        >
          <div className="modal">
            <div className="modal-header">
              <Typography className="text-md font-medium text-[#403E6F]">
                Take Picture
              </Typography>
              <CancelIcon
                onClick={() => setIsOpened(false)}
                style={{
                  color: "#afafaf",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
            <div className="modal-body">
              <FaceDetector
                isOpened={isOpened}
                setIsCameraModalOpen={setIsCameraModalOpen}
                setPatientIdfromFaceDetection={setPatientIdfromFaceDetection}
              />
            </div>
            <div className="modal-footer">
              <button
                className="btn close-btn"
                onClick={() => setIsOpened(false)}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
}
